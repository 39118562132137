<template lang="">
  <BCard
    style="width: fit-content"
    class="border mb-0"
    no-body
  >
    <div class="px-2 py-1">
      <h4 class="font-weight-bolder text-dark">
        Chi tiết sử dụng
      </h4>
      <BTable
        ref="refTable"
        style="max-height: 400px"
        :sticky-header="true"
        class="position-relative"
        :items="historyData"
        responsive
        :striped="true"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        :busy="loading"
        :hover="true"
        :borderless="true"
      >
        <template #table-busy>
          <div class="d-flex-center text-dark my-2 gap-2">
            <b-spinner class="align-middle" />
            <strong>{{ $t('loading') }}</strong>
          </div>
        </template>
        <template
          v-for="column in tableColumns"
          v-slot:[`head(${column})`]=""
        >
          <span
            :key="column"
            class="text-dark text-nowrap"
          >
            {{ $t(`packageConfig.columns.${column}`) }}
          </span>
        </template>

        <template #cell(dateCreate)="{item}">
          {{ convertISODateTime(item.dateCreate).date }}
        </template>
      </BTable>
    </div>
  </BCard>
</template>
<script>
import { BCard, BSpinner, BTable } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import { convertISODateTime } from '@core/utils/filter'

export default {
  components: {
    BTable,
    BSpinner,
    BCard,
  },
  props: {
    historyData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const tableColumns = ref([
      'dateCreate',
      'searchCount',
      'bookCount',
      'issueCount',
    ])
    return { tableColumns, convertISODateTime }
  },
}
</script>
<style lang="">

</style>
