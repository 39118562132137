var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "border mb-0",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "px-2 py-1"
  }, [_c('h4', {
    staticClass: "font-weight-bolder text-dark"
  }, [_vm._v(" Chi tiết sử dụng ")]), _c('BTable', {
    ref: "refTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "400px"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.historyData,
      "responsive": "",
      "striped": true,
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "busy": _vm.loading,
      "hover": true,
      "borderless": true
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, _vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("packageConfig.columns.".concat(column))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(dateCreate)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.dateCreate).date) + " ")];
      }
    }], null, true)
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }